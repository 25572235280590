.reset-points-wrapper {
    min-height: 500px;
    padding: 10px;
}

.reset-points-title {
    text-align: center;
    font-weight: 600;
    font-family: 'Roboto';
}

.reset-points-desc {
    margin: 10px 0;

    font-weight: 400;
    font-family: 'Roboto';
}

.reset-points-list {

}

.reset-points-list li {
    margin-top: 5px;
}
